<template>
    <div class="memo-images-carousel">
        <image-lightbox />
        <div v-if="showCarouselActions" class="memod-images-actions-overlay">
            <button
                v-if="memoImages.length"
                type="button"
                class="show-image-lightbox btn"
                @click="$modal.show('image-lightbox', { imageUrl: memoImages[currentSwiperIndex].x2 })"
            >
                <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.2843 3.42825C14.2843 3.20734 14.4634 3.02825 14.6843 3.02825L19.2098 3.02825C19.4307 3.02825 19.6098 3.20734 19.6098 3.42825L19.6098 7.95374C19.6098 8.17465 19.4307 8.35374 19.2098 8.35374C18.9889 8.35374 18.8098 8.17465 18.8098 7.95374L18.8098 4.37783L14.122 9.06558C14.0353 9.15236 13.8383 9.09608 13.6821 8.93987C13.5259 8.78366 13.4696 8.58668 13.5564 8.4999L18.228 3.82825L14.6843 3.82825C14.4634 3.82825 14.2843 3.64917 14.2843 3.42825Z"
                        fill="white"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.35397 19.1135C8.35397 19.3344 8.17489 19.5135 7.95397 19.5135L3.42913 19.5135L3.42849 19.5135C3.23519 19.5135 3.07392 19.3764 3.03662 19.1941C3.03129 19.1681 3.02849 19.1411 3.02849 19.1135L3.02849 14.588C3.02849 14.3671 3.20758 14.188 3.42849 14.188C3.64941 14.188 3.82849 14.3671 3.82849 14.588L3.82849 18.2284L8.50003 13.5569C8.58681 13.4701 8.7838 13.5264 8.94001 13.6826C9.09622 13.8388 9.1525 14.0358 9.06572 14.1225L4.47474 18.7135L7.95397 18.7135C8.17489 18.7135 8.35397 18.8926 8.35397 19.1135Z"
                        fill="white"
                    />
                </svg>
            </button>
            <button class="add-images btn" type="button" @click="openUploadImageModal">
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="30"
                        height="30"
                        fill="white"
                        fill-opacity="0.01"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.0015 3.5H9.98947L9.97745 3.50058C8.6548 3.56427 7.44011 4.49127 7.5021 5.78879V8.5H6C4.61929 8.5 3.5 9.61929 3.5 11V24C3.5 25.3807 4.61929 26.5 6 26.5H24C25.3807 26.5 26.5 25.3807 26.5 24V11C26.5 9.61929 25.3807 8.5 24 8.5H22.4979V5.7888C22.5599 4.49127 21.3452 3.56427 20.0225 3.50058L20.0105 3.5H19.9985H10.0015ZM21.9999 9.5C21.9993 9.5 21.9986 9.5 21.9979 9.5C21.7218 9.5 21.4979 9.27614 21.4979 9V5.776V5.76223L21.4987 5.74849C21.5291 5.19676 20.9723 4.55224 19.9859 4.5H10.0141C9.02768 4.55224 8.47094 5.19676 8.50135 5.74849L8.5021 5.76223V5.776V9C8.5021 9.27614 8.27825 9.5 8.0021 9.5C8.00206 9.5 8.002 9.5 8.00195 9.5H6C5.17157 9.5 4.5 10.1716 4.5 11V24C4.5 24.8284 5.17157 25.5 6 25.5H24C24.8284 25.5 25.5 24.8284 25.5 24V11C25.5 10.1716 24.8284 9.5 24 9.5H22.002C22.0013 9.5 22.0006 9.5 21.9999 9.5Z"
                        fill="white"
                    />
                    <path d="M16 7H19" stroke="white" stroke-linecap="round" />
                    <path d="M15 21C12.25 21 10 18.75 10 16C10 13.25 12.25 11 15 11C17.75 11 20 13.25 20 16C20 18.75 17.75 21 15 21ZM15 12C12.8 12 11 13.8 11 16C11 18.2 12.8 20 15 20C17.2 20 19 18.2 19 16C19 13.8 17.2 12 15 12Z" fill="white" />
                </svg>
                Add Image
            </button>
            <div v-if="memoImages.length" class="memo-images-actions">
                <button
                    v-if="isCoverImage"
                    disabled
                    class="mark-as-cover btn"
                    type="button"
                >
                    <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M4.2073 7.77408C3.97138 7.77544 3.74462 7.68282 3.57711 7.51668L0.914351 4.85391C0.56631 4.50587 0.56631 3.94158 0.914351 3.59354C1.26239 3.2455 1.82668 3.2455 2.17472 3.59354L4.2073 5.63499L9.34643 0.486984C9.69447 0.138941 10.2588 0.138942 10.6068 0.486984C10.9548 0.835027 10.9548 1.39931 10.6068 1.74736L4.83749 7.51668C4.66998 7.68282 4.44322 7.77544 4.2073 7.77408Z" fill="#1DDBB7" />
                    </svg>
                    Cover
                </button>
                <button
                    v-else
                    class="mark-as-cover btn"
                    type="button"
                    @click="markCoverImage"
                >
                    Make Cover
                </button>
                <button type="button" class="delete-memo-image btn" @click="deleteImage">
                    <svg
                        width="11"
                        height="13"
                        viewBox="0 0 11 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.99857 0.866699C4.26219 0.866699 3.66523 1.46365 3.66523 2.20003V2.4667H2.23801H2.23773H1.10846C0.961188 2.4667 0.841797 2.58609 0.841797 2.73337C0.841797 2.88064 0.961188 3.00003 1.10846 3.00003H1.98514L2.44065 11.3394C2.47927 12.0465 3.06388 12.6 3.772 12.6H8.04445C8.75258 12.6 9.33718 12.0465 9.3758 11.3394L9.83131 3.00003H10.7085C10.8557 3.00003 10.9751 2.88064 10.9751 2.73337C10.9751 2.58609 10.8557 2.4667 10.7085 2.4667H9.57872H9.57844H8.15151V2.20003C8.15151 1.46365 7.55455 0.866699 6.81817 0.866699H4.99857ZM2.97319 11.3103L2.51927 3.00003H3.9319H7.88484H9.29719L8.84326 11.3103C8.82009 11.7346 8.46933 12.0667 8.04445 12.0667H3.772C3.34713 12.0667 2.99636 11.7346 2.97319 11.3103ZM7.61818 2.4667V2.20003C7.61818 1.75821 7.26 1.40003 6.81817 1.40003H4.99857C4.55674 1.40003 4.19857 1.7582 4.19857 2.20003V2.4667H7.61818ZM6.18846 4.0667C6.18846 3.91206 6.0631 3.7867 5.90846 3.7867C5.75382 3.7867 5.62846 3.91206 5.62846 4.0667V11C5.62846 11.1547 5.75382 11.28 5.90846 11.28C6.0631 11.28 6.18846 11.1547 6.18846 11L6.18846 4.0667ZM7.89642 3.78693C8.05093 3.79322 8.17109 3.92358 8.16479 4.07809L7.88244 11.0114C7.87615 11.1659 7.74579 11.2861 7.59128 11.2798C7.43677 11.2735 7.31661 11.1432 7.3229 10.9886L7.60526 4.05531C7.61155 3.90079 7.74191 3.78064 7.89642 3.78693ZM3.65213 4.07809C3.64584 3.92358 3.766 3.79322 3.92051 3.78693C4.07502 3.78064 4.20538 3.90079 4.21167 4.05531L4.49402 10.9886C4.50032 11.1432 4.38016 11.2735 4.22565 11.2798C4.07114 11.2861 3.94078 11.1659 3.93449 11.0114L3.65213 4.07809Z"
                            fill="white"
                        />
                    </svg>
                </button>
            </div>
        </div>
        <swiper
            v-if="memoImages.length"
            ref="memoImagesSwiper"
            class="swiper"
            :options="swiperOptions"
            @ready="swiperIsReady"
        >
            <swiper-slide v-for="image in memoImages" :key="image.filesystem_id">
                <img
                    class="w-100 img-fluid"
                    :src="image.x1"
                    :srcset="`${image.x1} 1x, ${image.x2} 2x, ${image.x3} 3x`"
                    alt="carousel image"
                >
            </swiper-slide>
            <div v-show="memoImages.length > 1" slot="pagination" class="swiper-pagination-memo" />
            <div v-show="memoImages.length > 1" slot="button-prev" class="swiper-button-prev" />
            <div v-show="memoImages.length > 1" slot="button-next" class="swiper-button-next" />
        </swiper>
        <div v-else class="placeholder-slide" />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { MEMO_FILES_FIELDS_NAMES } from "@/utils/constants";

export default {
    name: "MemoImagesCarousel",
    components: {
        Swiper,
        SwiperSlide,
        imageLightbox: () => import(/* webpackChunkName: "image-lightbox" */ "@c/organisms/modals/image-lightbox")
    },
    props: {
        memoImages: {
            type: Array,
            default() {
                return [];
            }
        },
        showCarouselActions: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentSwiperIndex: 0,
            swiperOptions: {
                pagination: {
                    el: ".swiper-pagination-memo"
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        };
    },
    computed: {
        isCoverImage() {
            return this.memoImages[this.currentSwiperIndex].field_name == "cover-image";
        }
    },
    watch: {
        memoImages(newMemoImagesArray) {
            // The currentSwiperIndex is the current position of the carousel being showed to the user, if the user
            // delete images and the memoimages array has less images than the current index we need to update the currentSwiperIndex
            if (newMemoImagesArray.length && (newMemoImagesArray.length - 1) < this.currentSwiperIndex) {
                this.currentSwiperIndex = newMemoImagesArray.length - 1;
            }

            if (this.$refs.memoImagesSwiper) {
                this.$refs.memoImagesSwiper.$swiper.update();
            }

        }
    },
    methods: {
        swiperIsReady() {
            this.currentSwiperIndex = this.$refs.memoImagesSwiper.$swiper.activeIndex;

            this.$refs.memoImagesSwiper.$swiper.on("activeIndexChange", () => {
                this.currentSwiperIndex = this.$refs.memoImagesSwiper.$swiper.activeIndex;
            });
        },
        openUploadImageModal() {
            const uploadImageParams = {
                fieldName: MEMO_FILES_FIELDS_NAMES.MEMO_IMAGE,
                stencilOrientations: {
                    horizontal: {
                        width: 1334, height: 1000
                    },
                    vertical: {
                        width: 1000, height: 1334
                    }
                }
            }
            this.$modal.show("upload-image-modal", uploadImageParams)
        },
        markCoverImage() {
            this.$emit("mark-cover-image", this.memoImages[this.currentSwiperIndex].filesystem_id);
        },
        deleteImage() {
            this.$emit("delete-image", this.memoImages[this.currentSwiperIndex].filesystem_id);
        }
    }
}
</script>

<style lang="scss" scoped>
.memo-images-carousel {
    height: 250px;
    color: white;
    position: relative;
    margin-bottom: 50px;

    .memod-images-actions-overlay {
        position: absolute;
        width: 100%;
        height: 100%;

        .show-image-lightbox {
            padding: 0;
            background-color: rgba(0, 0, 0, .6);
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 2;
            border-radius: 4px;

            &:focus {
                box-shadow: none;
            }
        }

        .add-images {
            display: flex;
            flex-direction: column;
            color: inherit;
            padding: 0;
            align-items: center;
            font-weight: normal;
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            svg {
                margin-bottom: 10px;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .memo-images-actions {
            display: flex;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 2;

            .mark-as-cover {
                color: white;
                background-color: rgba(0, 0, 0, .6);
                padding: 4.5px 8px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }

                &:focus {
                    box-shadow: none;
                }

                &[disabled] {
                    opacity: 1;
                }
            }

            .delete-memo-image {
                margin-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, .6);
                padding: 0;
                width: 29px;
                height: 29px;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .swiper {
        height: inherit;

        .swiper-slide {
            display: flex;
            align-items: center;
        }

        .swiper-pagination-memo {
            position: absolute;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 25px;
            left: 0;
            width: 100%;

            /deep/ .swiper-pagination-bullet {
                background: white;
                opacity: 1;

                &.swiper-pagination-bullet-active {
                    background: $primary-color;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            background-color: rgba(0, 0, 0, .6);
            width: 24px;
            height: 24px;
            color: white;
            border-radius: 4px;
            padding: 0;

            &::after {
                font-size: 16px;
            }
        }

        .swiper-button-next {
            right: 20px;
        }

        .swiper-button-prev {
            left: 20px;
        }

        .placeholder-slide {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100px;
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 70%;
            bottom: -1px;
            background: linear-gradient(0deg, rgba(15,15,16,1) 0%, rgba(15,15,16,0) 100%);
            z-index: 1;
        }
    }

}
</style>
